import wrapWithProvider from './src/boot/wrap-with-provider'


export const onClientEntry = () => {
  const script = document.createElement('script')

  script.src = '/polyfill.min.js'
  script.defer = true
  document.head.appendChild(script)
}

export const wrapRootElement = wrapWithProvider
